.App {
    background-color: #f5f5f5;
    width: 100vw;
    height: 100vh;
}

.headingContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: end;
    margin-bottom: 2em;
}

.headingContainer img {
    width: 5vw;
}

.container {
    background-color: white;
    position: absolute;
    left: 25%;
    right: 25%;
    top: 10%;
    bottom: 10%;
    display: flex;
    flex-direction: column;
    padding: 2em;
    border-radius: 10px;
}

@media only screen and (max-width: 1024px) {
    .container {
        left: 10%;
        right: 10%;
    }

    .headingContainer img {
        width: 10vw;
    }
}

@media only screen and (max-width: 768px) {
    .container {
        left: 5%;
        right: 5%;
        top: 5%;
        bottom: 5%;
        padding: 1em;
    }

    .headingContainer img {
        width: 12vw;
    }

    .headingContainer h1 {
        font-size: 1.2em;
    }
}

.container .heading {
    margin: 0;
}

.mapContainer {
    flex: 1;
    width: 100%;
}
